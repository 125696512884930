(function(settings){
  var appId = settings.app_id !== 'undefined' ? settings.app_id : '';
  if (!appId) {
    return;
  }
  var backgroundColor = typeof settings.background_color !== 'undefined' ? settings.background_color : '#333333';
  var buildElement = function(classes, id = null, innerHTML = null) {
    var element = document.createElement('div');
    Object.keys(classes).forEach(function(key) {
      element.style[key] = classes[key];
    });
    if(id) {
      element.setAttribute('id', id);
    }
    element.innerHTML = innerHTML;
    return element;
  }
  var loadChat = function(open) {
    if (!window.Intercom) {
      var w=window;
      var ic=w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", window.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/" + appId + "/";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
      }
      l();
    }
    if (open) {
      logo.style.opacity = '0';
      close.style.opacity = '1';
      close.style.transform = 'rotate(0deg)';
      window.Intercom('show');
    }
    var counter = 0;
    var interval = setInterval(function(){
      counter++;
      if (window.Intercom.booted) {
        if(document.querySelector('#intercom-facade-btn') !== null) {
          document.querySelector('#intercom-facade-btn').remove();
        }
        clearInterval(interval);
      } else if (counter > 10) {
        clearInterval(interval);
      }
    }, 1000);
    return true;
  }
  var logoHtml = `
<svg aria-hidden="true" viewBox="1 1 60 60" fill="white"><path d="M27.765 42.244c-8.614 0-15.622-7.008-15.622-15.622S19.151 11 27.765 11s15.622 7.008 15.622 15.622-7.007 15.622-15.622 15.622zm0-28.398c-7.045 0-12.775 5.73-12.775 12.775s5.73 12.775 12.775 12.775 12.775-5.73 12.775-12.775-5.73-12.775-12.775-12.775z"></path><path d="M34.869 39.146l4.014-3.738 9.286 9.114a3.164 3.164 0 01-.07 4.562l-.071.066a3.163 3.163 0 01-4.561-.257l-8.598-9.747zM27.77 34.173c-2.882 0-5.412-.876-7.656-2.526a1.002 1.002 0 01-.35-.81c.008-.461.445-.969 1.02-.959.284.005.493.153.713.308 1.837 1.302 3.832 1.971 6.275 1.971 1.875 0 4.492-.476 6.314-2.118a.98.98 0 01.638-.261.92.92 0 01.686.241c.222.209.33.527.336.735a1.02 1.02 0 01-.318.775c-1.333 1.237-4.262 2.644-7.658 2.644z"></path></svg>
`;
  var logo = buildElement({
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    width: '100%',
    transform: 'rotate(0deg) scale(1)',
    transition: 'transform 0.16s linear 0s, opacity 0.08s linear 0s'
  }, null, logoHtml);
  var closeHtml = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    fill="white"
    d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z">
  </path>
</svg>
`;
  var close = buildElement({
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    WebkitBoxPack: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    width: '100%',
    transition: 'transform 0.16s linear 0s, opacity 0.08s linear 0s',
    opacity: '0',
    transform: 'rotate(-30deg)',
  }, null, closeHtml);
  var launcher = buildElement({
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    cursor: 'pointer',
    transformOrigin: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden',
    WebkitBackfaceVisibility: 'hidden',
    WebkitFontSmoothing: 'antialiased'
  });
  var region = buildElement({
    fontFamily:
      "intercom-font, 'Helvetica Neue', 'Apple Color Emoji', Helvetica, Arial, sans-serif",
    fontSize: '100%',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontVariantLigatures: 'normal',
    fontVariantCaps: 'normal',
    fontVariantEastAsian: 'normal',
    fontVariantPosition: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
    textDecorationLine: 'none',
    textDecorationStyle: 'initial',
    textDecorationColor: 'initial',
    textDecoration: 'none',
    textIndent: '0px',
    textShadow: 'none',
    textTransform: 'none',
    boxSizing: 'content-box',
    WebkitTextEmphasisStyle: 'none',
    WebkitTextEmphasisColor: 'initial',
    WebkitFontSmoothing: 'antialiased',
    lineHeight: 1
  });
  var wrapper = buildElement({
    zIndex: 2147483004,
    position: 'fixed',
    bottom: '20px',
    display: 'block',
    right: '20px',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    boxShadow:
      'rgba(0, 0, 0, 0.0588235) 0px 1px 6px 0px, rgba(0, 0, 0, 0.156863) 0px 2px 32px 0px',
    backgroundColor: backgroundColor,
  }, 'intercom-facade-btn');
  launcher.append(logo);
  launcher.append(close);
  region.append(launcher);
  region.addEventListener('click', function() {
    loadChat(true);
  });
  region.addEventListener('mouseenter', function() {
    loadChat(false);
  });
  wrapper.append(region);
  document.querySelector('body').append(wrapper);
  if (typeof settings.custom_launcher_selector !== 'undefined') {
    document.querySelectorAll(settings.custom_launcher_selector).forEach(function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault();
        loadChat(true);
      })
    });
  }
}(window.intercomSettings));
